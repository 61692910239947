import React, { useEffect } from 'react';
import { Spinner, Text } from '@ci/atoms';
import { getLocale, isLoadingLocale } from '@myci/intl';
import { ViewHeader } from '@ci/layout';
import {
	fetchPrivacyPolicy,
	selectIsFetchingPrivacyPolicy,
	selectPrivacyPolicy,
} from '@myci/domain-data-api';
import { Grid } from '@myci/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { isNilOrEmpty } from 'ramda-extension';

import m from '../messages';
import PublicLayout from '../layouts/PublicLayout';

const PrivacyPolicy = () => {
	const dispatch = useDispatch();
	const privacyPolicy = useSelector(selectPrivacyPolicy);
	const isLocaleLoading = useSelector(isLoadingLocale);
	const isFetchingPrivacyPolicy = useSelector(selectIsFetchingPrivacyPolicy);
	const currentLocale = useSelector(getLocale);

	useEffect(() => {
		if (
			(!isLocaleLoading && isNilOrEmpty(privacyPolicy)) ||
			(privacyPolicy && privacyPolicy.locale !== currentLocale)
		) {
			dispatch(fetchPrivacyPolicy());
		}
	}, [privacyPolicy, isLocaleLoading, currentLocale]);

	return (
		<PublicLayout isPrivacyPolicyHidden>
			{isLocaleLoading || isFetchingPrivacyPolicy ? (
				<Spinner message={m.loadingApplication} />
			) : (
				<section>
					<Grid container>
						<ViewHeader title={<Text message={m.privacyPolicy} variant="viewTitle" />} />
						{privacyPolicy && (
							<>
								{/* eslint-disable-next-line react/no-danger */}
								<div dangerouslySetInnerHTML={{ __html: privacyPolicy.data }} />
							</>
						)}
					</Grid>
				</section>
			)}
		</PublicLayout>
	);
};

export default PrivacyPolicy;
